import { useEffect, useState } from 'react';

const fetchUrl = 'https://worldtimeapi.org/api/timezone/etc/utc.json';

const useServerTime = () => {
  const [currentTime, setCurrentTime] = useState(null);
  useEffect(() => {
    const setTime = async () => {
      try {
        const timeResponse = await fetch(fetchUrl);
        const { datetime } = await timeResponse.json();
        setCurrentTime(new Date(datetime).getTime());  
      } catch (e) {
        setCurrentTime(new Date().getTime());
        console.error('Failed to fetch server time. Falling back to client time.');  
      }
    }
    setTime();
  }, []);
  return currentTime;
};

export default useServerTime;
